<div class="ckeditorWrapper">
  <div class="ChatMsg __commentUserMn scroll" [ngClass]="from === 'review' ? 'reviewChatMsg' : 'notesPopupChatMsg'">
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frost</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul>
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frostt</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  <ul> 
    <li>
        <snl-profile-image [profileInitial]="'JKF'" [size]="'small'"></snl-profile-image>
    </li>
    <li>
        <p class="body-b2 UserName">Jack Frosty</p> 
        <span class="caption">12 Dec 2024 - 10:10</span>
        <p class="body-b1 commentUser">Please let us know if you see anything. I think attached has been well cleaned up,  but in case I missed anything</p>
    </li>
  </ul> 
  </div>

<div>
    <ul class="Notes__ul"><li *ngFor="let notes of notesArr" [innerHtml]="notes"></li></ul>
    <div class="main-container">
      <div class="editor-container editor-container_classic-editor focused_container_editor" #editorContainerElement>
        <div class="editor-container__editor" [ngClass]="!collapsedToggle ? 'OptionsHideEdtr' : ''">
          <svg-icon (click)="toggleClick()" class="df-c chevronToggle" src="assets/icons/chevron.svg"></svg-icon>            
          <div #editorElement>
            <ckeditor [editor]="Editor" [config]="config" *ngIf="isLayoutReady" (change)="checkChanges($event)"/>
            <button class="SendMsg section-title" type="button" (click)="addNotes()">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>