import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule } from 'snl-complib';
import { NotesComponent } from "../notes/notes.component";

@Component({
  selector: 'app-notes-popup',
  standalone: true,
  imports: [ModalModule, NotesComponent],
  templateUrl: './notes-popup.component.html',
  styleUrl: './notes-popup.component.scss'
})
export class NotesPopupComponent {
  @Input() open: boolean = false;
  @Output() onButtonClick: EventEmitter<any | null> = new EventEmitter();

  closeModalPopUp() {
    this.onButtonClick.emit({}) 
  }
}
